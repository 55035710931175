/* Style for the form container */
form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9; /* Light background to contrast with input boxes */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* General style for input fields */
input[type="email"],
input[type="password"] {
    width: 90%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    transition: border 0.3s ease, box-shadow 0.3s ease;
    background-color: #fff;
}

/* Input fields focus state */
input[type="email"]:focus,
input[type="password"]:focus {
    border-color: #006BA6; /* Color matching your design theme */
    box-shadow: 0 0 5px rgba(0, 107, 166, 0.5);
    outline: none;
}

/* Button styling */
button[type="Submit"] {
    width: 100%;
    padding: 10px;
    background-color: #006BA6;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="Submit"]:hover {
    background-color: #004f7a;
}

/* Centering form and making it responsive */
form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
}