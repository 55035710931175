/* src/pages/Profile.css */

/* Profile container */
.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9;
    padding: 20px;
  }
  
  /* Basic Info */
  .profile-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: center;
  }

  .profile-title {
    font-size: 24px;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .profile-item {
    font-size: 16px;
    color: #555555;
    margin-bottom: 10px;
  }

  .stats-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: center;
  }

  .upload-button-container {
    text-align: center;
    margin-top: 20px;
  }

  .upload-button {
    background-color: #1db954; /* Spotify green */
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 24px;
    font-size: 1em;
    cursor: pointer;
  }

  .upload-button:hover {
    background-color: #1ed760;
  }
  
  
  .error-message {
    color: red;
    text-align: center;
    font-size: 18px;
  }
  
  .loading-message {
    text-align: center;
    font-size: 18px;
    color: #555555;
  }