/* Dropdown container */
.dropdown {
    margin: 20px 0;
}

/* Dropdown button styling */
.dropdown-title {
    background-color: #006BA6;
    color: white;
    padding: 10px 15px;
    font-size: 1.2em;
    border: none;
    cursor: pointer;
    text-align: left;
    width: 100%;
}

/* Change background color on hover */
.dropdown-title:hover {
    background-color: #00568d;
}

/* Dropdown content */
.dropdown-content {
    margin-top: 10px;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-top: none;
    background-color: #f9f9f9;
}