/* Main container styling */
.friend-pill {
    background-color: #0496FF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 40px 20px 20px 20px; /* Increased top padding */
    margin: 20px auto;
    border-radius: 30px;
    max-width: 350px;
    min-width: 300px;
    text-align: center;
    display: block;
  }
  
  /* Heading styling */
  .friend-pill h2 {
    color: #333;
    font-size: 24px;
    margin: 10px 0 15px 0;
  }
  
  /* Paragraph or text styling */
  .friend-pill p {
    color: #3c3c3c;
    margin: 0 auto;
    max-width: 70%;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .friend-pill {
        padding: 30px 15px 15px 15px; /* Adjusted top padding for smaller screens */
        margin: 15px;
        max-width: 100%;
        width: 100%;
    }
  
    .friend-pill h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }
  
    .friend-pill p {
        font-size: 14px;
        line-height: 1.4;
    }
  }
  
  /* Extra small screens */
  @media (max-width: 480px) {
    .friend-pill {
        padding: 25px 10px 10px 10px; /* Adjusted top padding for very small screens */
        margin: 10px;
    }
  
    .friend-pill h2 {
        font-size: 20px;
    }
  
    .friend-pill p {
        font-size: 14px;
    }
  }