.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-content {
  display: flex;
  justfiy-content: flex-end;
  padding: 20px;
  margin: 20px
}

/* Outer container for artist pills */
.artists-container {
  display: flex; /* Enable Flexbox layout */
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 20px; /* Space between the pills */
  margin: 20px 0; /* Add some margin at the top and bottom */
}

/* Outer container for friend pills */
.friends-container {
  display: grid; 
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 0;
  margin: 0 0;
  padding: 0;
}

/* Global styles for <p> and <h1> to <h6> elements */
p, h1, h2, h3, h4, h5, h6 {
  text-align: justify;
  margin: 10px;
}
