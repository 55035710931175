/* src/pages/PublicProfile.css */

.public-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4;
  }
  
  .public-profile-card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .public-profile-title {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .public-profile-item {
    margin-bottom: 10px;
    color: #666;
  }
  
  .error-message {
    color: red;
    text-align: center;
  }
  
  .loading-message {
    text-align: center;
  }