/* Sidebar Styling */
.sidebar-container {
  position: fixed; /* Make the sidebar fixed to the left */
  top: 0;
  left: 0;
  width: 250px; /* Width of the sidebar */
  height: 100%;
  background-color: #D81159; /* Sidebar color */
  transition: transform 0.3s ease; /* Smooth transition */
  transform: translateX(-100%); /* Initially hide the sidebar off-screen */
  z-index: 1000; /* Ensure it appears above other elements */
  font-size: 24px;
}

/* Sidebar open state */
.sidebar-open {
  transform: translateX(0); /* Move sidebar into view */
}

/* Content container styling */
.content-container {
  margin-left: 0; /* Initial margin */
  transition: margin-left 0.3s ease; /* Smooth transition */
}

/* Shift the content when the sidebar is open */
.content-container.shifted {
  margin-left: 250px; /* Same width as the sidebar */
}

.sidebar-container ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-container li a:hover {
  text-decoration: none;
}