/* TextCard.css */
.text-card {
    background-color: #FFFFFF;
    /*border: 1px solid #ddd;*/
    /*border-radius: 8px;*/
    padding: 20px;
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
    margin: 10px;
    flex: 1;
}

.card-title {
    font-size: var(--font-size-header);
    margin-bottom: 10px;
    font-weight: normal;
    text-align: center;
    margin: 20px;
}

.card-content-bold {
    font-size: var(--font-size-subheader);
    font-weight: bold;
}

.card-content {
  font-size: var(--font-size-subheader);
}

/* General Flexbox Alignment for Cards in Section (Section.css) */
@media (min-width: 768px) {
  .section {
    flex-direction: row;
    justify-content: space-around;
  }
}