    .users-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
  }
  
  .section-title {
    font-size: 1.5rem;
    color: #000; /* Black for a bold title */
  }
  
  .users-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }


  