@import './variables.css';

.main-content {
    margin-top: calc(var(--padding-top-bottom) * 2);
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.App {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}