/* Section.css */
@import './variables.css';

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

}

  .section:hover {
    background-color: rgba(255, 188, 66, 0.6);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .section-title {
    font-size: var(--font-size-header);
    color: #000; /* Neutral dark gray */
    margin-bottom: 0.5rem;
  }
  
  .section-subtitle {
    font-size: var(--font-size-subheader);
    color: #666; /* Lighter gray for subtitle */
  }

@media (max-width: 768px) {
    .section-title {
        font-size: var(--font-size-header);
    }

    .section-subtitle {
        font-size: var(--font-size-subheader);
    }

    .section {
        padding: calc(var(--padding-top-bottom) / 2) calc(var(--padding-left-right) / 2);
    }
}
