/* About.css */
@import '../components/variables.css';

.about-container {
    text-align: left;
    width: 100%;
}

.about-container h2,
.about-container h3,
.about-container p,
.about-container ul {
    text-align: left;
    margin: 10px auto;
    padding: 10px;
    list-style-position: inside;
}

.about-container ul {
    padding-left: 20px; /* Add some padding to indent the list */
}

.about-container li {
    margin-bottom: 8px; /* Add some space between list items */
}