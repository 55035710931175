.comment-form {
    margin-top: 20px;
}

.comment-form h2 {
    font-size: 24px;
}

.comment-form form {
    margin-bottom: 20px;
}

.comment-form textarea {
    width: 80%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.comment-form button {
    background-color: #006BA6;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.comment-box button:hover {
    background-color: #005b94;
}

.comments-list {
    margin-top: 20px;
}

.comment {
    background-color: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
}