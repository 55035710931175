/* Font */
:root {
    --font-family: 'JetBrains Mono', monospace;
    --font-size-header: 35px;
    --font-size-subheader: 25px;
    --font-size-regular: 14px;

    /* Font color */
    --font-color-primary: #000000;

    /* Background */
    --background-color-primary: #0085CC;
    --background-color-secondary: #FFA500;

    /* Spacing */
    --padding-top-bottom: 30px;
    --padding-left-right: 20px;
    --padding-header: var(--padding-top-bottom) var(--padding-left-right);
    --margin-link: 1rem;
}

body {
    font-family: var(--font-family);
    font-size: var(--font-size-regular);
    color: var(--font-color-primary);
}

@media (max-width: 768px) {
    :root {
        --font-size-header: 24px;
        --font-size-subheader: 20px;
        --font-size-regular: 12px;
    }
}