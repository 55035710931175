/* Button.css */
.button {
    background-color: var(--background-color-secondary);
    color: var(--font-color-primary);
    padding: 10px 15px;
    border-radius: 10px;
    text-decoration: none;
    font-size: 1em;
    border: 2px solid var(--background-color-secondary);
    margin: 0 20px 10px 0;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: white;
    border: 2px solid var(--background-color-secondary);
    cursor: pointer;
  }