/* Home.css */
@import '../components/variables.css';
.home {
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.users-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .user-pill {
    background-color: #006BA6;
    color: white;
    padding: 10px 15px;
    border-radius: 20px;
    text-decoration: none;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .user-pill:hover {
    background-color: #004f7c;
  }