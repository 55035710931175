@import './variables.css';

/* Header container */
.header {
    background: linear-gradient(to right, 
                var(--background-color-primary) 33%, 
                var(--background-color-primary) 33%, 
                rgba(0, 107, 166, 0.2) 100%);
    padding: var(--padding-header);
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 1000;
}

/* Title styling */
.header h1 {
    margin: 0;
    font-size: var(--font-size-header);
    text-align: center;
    position: relative;
}

/* Navigation links container */
.nav-links-right {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
}

.nav-links-left {
    position:absolute;
    left: 20px;
}

    /* Navigation link styles */
    .nav-items .auth-link {
        margin: var(--margin-link);
        color: var(--font-color-primary);
        text-decoration: none;
        transition: all 0.3s ease;
    }
  
    .nav-items .auth-link:hover {
        text-decoration: none;
        color: var(--background-color-secondary);
    }

/* Settings icon styling */
.settings-icon {
    position: relative;
    top: 50%;
    color: var(--font-color-primary);
    font-size: 1.5em;
    cursor: pointer;
    transition: color 0.3s ease;
}

.settings-icon:hover {
    color: var(--background-color-secondary); /* Change color on hover */
}

/* Hamburger icon styling */
.hamburger {
    font-size: 1.8em;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
}

.hamburger:hover {
    color: var(--background-color-secondary); /* Accent color on hover */
}


/* Dropdown menu styling */
.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 10px;
    background-color: var(--background-color-primary);
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

/* Dropdown link styling */
.auth-link, .profile-link {
    color: var(--font-color-primary);
    text-decoration: none;
    font-size: 1.2em;
    margin: 5px 0;
}

.auth-link:hover, .profile-link:hover {
    text-decoration: underline;
}

/* Title link styling */
.title-link {
    color: white;
    text-decoration: none;
}

.title-link:hover {
    text-decoration: none;
}