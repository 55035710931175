/* UploadData.css */

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    width: 100%;
  }
  
  .dropzone {
    width: 400px;
    height: 200px;
    border: 2px dashed #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .file-input {
    display: none; /* Hidden input for selecting files by clicking */
  }
  
  .file-details {
    font-size: 16px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }

  .loading-icon {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }